import axios from 'axios';

import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext } from 'react'

const SettingsTool = () => {
    
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const queryCondition = usershopidref !== 0 ? usershopidref : usershopid;
    const [deptdata, setdeptdata] = useState([]);
    const [iTGdata, setITGdata] = useState([]);
    const [AccCode, setAccCode] = useState([]);
    const [data, setData] = useState({
        id: "",
        gst5: "",
        gst12: "",
        gst18: "",
        printername:"",
        gst28: "",
        chmid: "",
        printertype:0,
        printertypesize:0,
        chmapikeyname:"",
        chanelstatus:0,
        chmhostname:"",
        chmuserapikey: "",
        billprintline1: "",
        billprintline2: "",
        billprintline3: "",
        billprintline4: "",
        billprintline5: "",
        restdlvchamt: "",
        hideonline:0,
        taxondinein: 0,
        taxondelivery: 0,

        askprintkot: 0,printbill: 0,printkot: 0,

        taxontakeaway: 0,
        taxononline: 0,
        restdlvchperc: "",
        restservicechperc: "",
        billprintlineTC1: "",
        billprintlineTC2: "",
        billprintlineTC3: "",
        billprintlineTC4: "",
        billprintlineTC5: "",
        gstno: "",
        saccode: "",
        gsttype: 0,
        printlogo:0,
        deptcode: 0,
        wastagedeptcode:0,
        rwcode: 0,
        fgcode: 0,
        roomshopid:0,
        showtaxsummary: 0,
        changecheckoutdate: 0,
        showbatchinpurchase:0,
        hidekot:0,
        hidedelivery:0,
        hidetakeaway:0,
        print_hidekotno: 0,
        fs_kottype: 0,
        fs_kotno: 0,
        fs_cname: 0,
        itwashow: 0,
        askqtyboxonsale:0,
        hotelshopid: 0,
        otheritemprintname:0,
        accountname: "", accountno: "", ifsc: "", bankname: "", branch: "",
        dcotont: 0,takeawaypaycode:0,
    })
    const { gst5, saccode, accountname,printlogo,askprintkot,printbill,printkot, accountno, ifsc,askqtyboxonsale, bankname, branch, gst12,takeawaypaycode, gst18, gst28, gstno, billprintline1, billprintline2, billprintline3, billprintline4, billprintline5, billprintlineTC1, billprintlineTC2, billprintlineTC3, billprintlineTC4, gsttype, showtaxsummary, chmid,chmuserapikey,chmapikeyname,chmhostname,printertype,chanelstatus,billprintlineTC5,taxondinein,taxondelivery,taxontakeaway,taxononline,showbatchinpurchase,hideonline,hidetakeaway,hidedelivery,hidekot, restdlvchamt, restdlvchperc, restservicechperc, changecheckoutdate, print_hidekotno, rwcode, fgcode, wastagedeptcode,deptcode, roomshopid,hotelshopid, fs_cname, fs_kottype,otheritemprintname, fs_kotno, itwashow, dcotont,printername,printertypesize } = data;

    const calcelForm = (e) => {
        window.location.assign("/")
    }

    const savedata = async () => {
        
        await axios.put(`${hostlink}/shopmas/${usershopid}/${deptcode}`, data)
            .then((responce) => {
                toast.success("Save Sucessfull")
                // console.log(data)
                // return false
                setInterval(() => {
                    localStorage.removeItem('MYPCKARTUSER');
                    window.location.assign("/")
                }, 1000);
            })
            .catch(() => {
                toast.error("Something went wrong, Please check it..")
            })
    }

    const onchange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    useEffect(() => {

        axios.get(`${hostlink}/${usershopid}/accmascashbank`)
        .then((Response) => { setAccCode(Response.data) })


        axios.get(`${hostlink}/${queryCondition}/ItemGroup`)
            .then((Response) => { setITGdata(Response.data) })

        axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
            console.log(`${hostlink}/shopmas/${usershopid}`);

            axios.get(`${hostlink}/shopmas/${usershopid}`)
            .then((resp) => {
                setData(resp.data)
                console.log(resp.data);
            })
            .catch(() => {
                console.log("Data Not Fatch...")
            })
    }, [usershopid])

    return (
        <div>
            <div className='container p-1'>
                <h5 className='font-monospace text-danger'>System Configuration</h5>
                <div className='row'>
                    {/* Column 1 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-6 '><label htmlFor="">SAC Code </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='saccode' value={saccode} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">GSTIN NO </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='gstno' value={gstno} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">Bank Ac Name </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='accountname' value={accountname} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">Bank Ac No </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='accountno' value={accountno} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">Bank IFSC Code </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='ifsc' value={ifsc} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">Bank Name </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='bankname' value={bankname} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">Bank Branch Name </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='branch' value={branch} type="text" /></div>

                            <div className='col-md-6 '><label htmlFor="">Room GST 5 % on Amount </label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='gst5' value={gst5} type="text" /></div>
                            <div className='col-md-6 '><label html For=""> Room GST 12 % on Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} name='gst12' value={gst12} type="text" /></div>
                            <div className='col-md-6 '><label htmlFor=""> Room GST 18 % on Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} name='gst18' value={gst18} type="text" /></div>
                            <div className='col-md-6 '><label htmlFor=""> Room GST 28 % on Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="text" name='gst28' value={gst28} /></div>
                            <p></p>
                            <hr />
                            <div className='col-md-6 '><label htmlFor=""> Service charge % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="number" name='restservicechperc' value={restservicechperc} maxLength={2} /></div>
                            <div className='col-md-6 '><label htmlFor=""> Delivery charge % </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="number" name='restdlvchperc' value={restdlvchperc} maxLength={2} /></div>
                            <div className='col-md-6 '><label htmlFor=""> Delivery charge Amount </label></div>
                            <div className='col-md-4'><input onChange={(e) => { onchange(e) }} type="number" name='restdlvchamt' value={restdlvchamt} /></div>

                            <p></p>
                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Hide Kot  </label></div>
                            <div className='col-md-4 '><select id="hidekot" onChange={(e) => { onchange(e) }} name='hidekot' value={hidekot} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>
                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Hide Delivery  </label></div>
                            <div className='col-md-4 '><select id="hidedelivery" onChange={(e) => { onchange(e) }} name='hidedelivery' value={hidedelivery} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>
                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Hide Takeaway  </label></div>
                            <div className='col-md-4 '><select id="hidetakeaway" onChange={(e) => { onchange(e) }} name='hidetakeaway' value={hidetakeaway} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>
                            
                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Hide Online  </label></div>
                            <div className='col-md-4 '><select id="hideonline" onChange={(e) => { onchange(e) }} name='hideonline' value={hideonline} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Show Batch In Purchase  </label></div>
                            <div className='col-md-4 '><select id="showbatchinpurchase" onChange={(e) => { onchange(e) }} name='showbatchinpurchase' value={showbatchinpurchase} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Dine In GST  </label></div>
                            <div className='col-md-4 '><select id="taxondinein" onChange={(e) => { onchange(e) }} name='taxondinein' value={taxondinein} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>

                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Delivery GST  </label></div>
                            <div className='col-md-4 '><select id="taxondelivery" onChange={(e) => { onchange(e) }} name='taxondelivery' value={taxondelivery} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>

                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Takeaway GST  </label></div>
                            <div className='col-md-4 '><select id="taxontakeaway" onChange={(e) => { onchange(e) }} name='taxontakeaway' value={taxontakeaway} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>

                            <div className='col-md-6'><label className='font-monospace' class="form-label"> Online GST  </label></div>
                            <div className='col-md-4 '><select id="taxononline" onChange={(e) => { onchange(e) }} name='taxononline' value={taxononline} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>

                        </div>
                    </div>
                    {/* Column 2 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-1 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline1' value={billprintline1} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-2 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline2' value={billprintline2} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-3 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline3' value={billprintline3} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-4 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline4' value={billprintline4} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Bl-Print-5 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintline5' value={billprintline5} type="text" /></div>

                            <div className='col-md-3 '><label htmlFor=""> T&C 1 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC1' value={billprintlineTC1} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 2 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC2' value={billprintlineTC2} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 3 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC3' value={billprintlineTC3} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 4 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC4' value={billprintlineTC4} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> T&C 5 </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='billprintlineTC5' value={billprintlineTC5} type="text" /></div>
                            
                            <p></p>
                            <div className='col-md-3 '><label htmlFor=""> Hostname </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='chmhostname' value={chmhostname} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Ch ID </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='chmid' value={chmid} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Key Name </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='chmapikeyname' value={chmapikeyname} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Ch API Key </label></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='chmuserapikey' value={chmuserapikey} type="text" /></div>
                            <div className='col-md-3 '><label htmlFor=""> Active </label></div>
                            <div className='col-md-9 '><select id="askqtyboxonsale" onChange={(e) => { onchange(e) }} name='chanelstatus' value={chanelstatus   } class="form-select w-50"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-3 '><label htmlFor=""> Printer </label></div>
                            <div className='col-md-9 '><select id="printertype" onChange={(e) => { onchange(e) }} name='printertype' value={printertype} class="form-select w-50"><option selected key={0} value={0}>Ask</option><option selected key={1} value={1}>Bluetooth</option><option selected key={2} value={2}>USB Direct Print</option></select></div>

                            <div className='col-md-3 '><label htmlFor=""> Printer Size</label></div>
                            <div className='col-md-9 '><select id="printertypesize" onChange={(e) => { onchange(e) }} name='printertypesize' value={printertypesize} class="form-select w-50"><option selected key={0} value={0}>NA</option><option selected key={1} value={32}>2 INCH</option><option selected key={2} value={48}>3 INCH</option></select></div>

                            <div className='col-md-3 '><span style={{fontSize:"12px"}}>Printer Name</span></div>
                            <div className='col-md-9 '><input onChange={(e) => { onchange(e) }} name='printername' value={printername} type="text" id='printername' /></div>

                            
                        </div>
                    </div>

                    {/* Column 3 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            {/* <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Recovery email address : </label></div>
                            <div className='col-md-5 '><input type="recoveryemail"  onChange={(e) => { onchange(e) }} name='recoveryemail' value={recoveryemail} class="form-control" id="recoveryemail" /></div> */}

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Takeaway Payment : </label></div>
                            <div className='col-md-5 '> <select id="takeawaypaycode" name='takeawaypaycode' value={takeawaypaycode} onChange={(e) => { onchange(e) }} class="form-select"> <option selected key={0} value={0}>Not Selected </option>{AccCode.map((x) => { return (<option key={x.id} value={x.id}> {x.accname} </option>) })}</select></div>


                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Finished Group : </label></div>
                            <div className='col-md-5 '> <select id="fgcode" name='fgcode' value={fgcode} onChange={(e) => { onchange(e) }} class="form-select"> <option selected key={0} value={0}>NA</option>{iTGdata.map((x) => { return (<option key={x.id} value={x.id}> {x.groupname} </option>) })}</select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Raw Meterial : </label></div>
                            <div className='col-md-5 '> <select id="rwcode" name='rwcode' value={rwcode} onChange={(e) => { onchange(e) }} class="form-select"> <option selected key={0} value={0}>NA</option>{iTGdata.map((x) => { return (<option key={x.id} value={x.id}> {x.groupname} </option>) })}</select></div>


                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Sale Department : </label></div>
                            <div className='col-md-5 '> <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onchange(e) }} class="form-select"> <option selected key={0} value={0}>NA</option>{deptdata.map((x) => { return (<option key={x.id} value={x.id}> {x.deptname} </option>) })}</select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Wastage Department : </label></div>
                            <div className='col-md-5 '> <select id="wastagedeptcode" name='wastagedeptcode' value={wastagedeptcode} onChange={(e) => { onchange(e) }} class="form-select"> <option selected key={0} value={0}>NA</option>{deptdata.map((x) => { return (<option key={x.id} value={x.id}> {x.deptname} </option>) })}</select></div>


                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Ask Qty Box : </label></div>
                            <div className='col-md-5 '><select id="askqtyboxonsale" onChange={(e) => { onchange(e) }} name='askqtyboxonsale' value={askqtyboxonsale} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Change Checkout Date : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='changecheckoutdate' value={changecheckoutdate} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Show Tax Summary : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='showtaxsummary' value={showtaxsummary} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>
                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Tax Type : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='gsttype' value={gsttype} class="form-select"><option selected key={0} value={0}>Exclude</option><option selected key={1} value={1}>Include</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Item wise Amount : </label></div>
                            <div className='col-md-5 '><select id="itwashow" onChange={(e) => { onchange(e) }} name='itwashow' value={itwashow} class="form-select"><option selected key={0} value={0}>Non Taxable</option><option selected key={1} value={1}>Taxable</option></select> </div>
                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Hide Kot Srno : </label></div>
                            <div className='col-md-5 '><select id="inputState" onChange={(e) => { onchange(e) }} name='print_hidekotno' value={print_hidekotno} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Devlivery Charge On : </label></div>
                            <div className='col-md-5 '><select id="dcotont" onChange={(e) => { onchange(e) }} name='dcotont' value={dcotont} class="form-select"><option selected key={0} value={0}>Taxable</option><option selected key={1} value={1}>Non Taxable</option></select> </div>


                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Kot No Font size : </label></div>
                            <div className='col-md-5 '><input onChange={(e) => { onchange(e) }} name='fs_kotno' value={fs_kotno} type="text" /></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Kot Type Font size : </label></div>
                            <div className='col-md-5 '><input onChange={(e) => { onchange(e) }} name='fs_kottype' value={fs_kottype} type="text" /></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> C Name Font size : </label></div>
                            <div className='col-md-5 '><input onChange={(e) => { onchange(e) }} name='fs_cname' value={fs_cname} type="text" /></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Warehouse ID : </label></div>
                            <div className='col-md-5 '><input name='hotelshopid' value={hotelshopid} type="text" /></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Hotel ID : </label></div>
                            <div className='col-md-5 '><input name='hotelshopid' value={roomshopid} type="text" /></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Show Item Print Name : </label></div>
                            <div className='col-md-5 '><select id="otheritemprintname" onChange={(e) => { onchange(e) }} name='otheritemprintname' value={otheritemprintname} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Print Logo : </label></div>
                            <div className='col-md-5 '><select id="printlogo" onChange={(e) => { onchange(e) }} name='printlogo' value={printlogo} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>


                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Print Bill : </label></div>
                            <div className='col-md-5 '><select id="printbill" onChange={(e) => { onchange(e) }} name='printbill' value={printbill} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Print Kot : </label></div>
                            <div className='col-md-5 '><select id="printkot" onChange={(e) => { onchange(e) }} name='printkot' value={printkot} class="form-select"><option selected key={1} value={1}>No</option><option selected key={0} value={0}>Yes</option></select></div>

                            <div className='col-md-7 text-end'><label className='font-monospace' class="form-label"> Ask Kot Print : </label></div>
                            <div className='col-md-5 '><select id="askprintkot" onChange={(e) => { onchange(e) }} name='askprintkot' value={askprintkot} class="form-select"><option selected key={0} value={0}>No</option><option selected key={1} value={1}>Yes</option></select></div>

                        </div>
                    </div>

                    {/* Save Button */}
                    <div className='col-md-6'></div>
                    <div className='col-md-6 text-end'>
                        <br />
                        <button class="btn btn-primary" onClick={() => { savedata() }}>Save</button>  &nbsp;
                        <button class="btn btn-outline-dark" onClick={() => { calcelForm() }}>Cancel</button>
                    </div>

                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default SettingsTool