import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const WifiLink = ({ ssid, password }) => {
  const wifiConfig = `WIFI:T:WPA;S:MPK;P:99106797;;`;

  // Android-specific WiFi Intent URL
  const wifiLink = `intent://wifi/#Intent;scheme=wifi;S:MPK;P:99106797;end`;

  return (
    <div>
      <h3>WiFi QR Code</h3>
      <QRCodeCanvas value={wifiConfig} size={200} />
      <p>
        Or click the link below to connect (Android only):
      </p>
      <a href={wifiLink} style={{ color: 'blue', textDecoration: 'underline' }}>
        Connect to {ssid} <br />
        {wifiLink}
      </a>
    </div>
  );
};

export default WifiLink;
