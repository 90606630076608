import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Loading';
const KitchenDisplayStatus = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
      const [messagedata, setMessagedata] = useState({
          messageShopid: "",
          messageShopVno: "",
          messageShopVnoMessage: "Please do it fast"
      })
      const { messageShopid, messageShopVno,messageShopVnoMessage } = messagedata;
  
  
      const handleOpenModal = async (shopvno) => {
          setMessagedata({ ...messagedata, messageShopid: usershopid, messageShopVno: shopvno })
          await setIsModalOpen(true);
          document.getElementById("messageShopVnoMessage").focus();
      };
      const handleCloseModal = () => { setIsModalOpen(false); };
      const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
      const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setMessagedata({ ...messagedata, [name]: sanitizedValue });
    }

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const audioRef = useRef(null);
  const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
  const usershopid = appState?.[0]?.id || 0;
  // Fetch data from the server at regular intervals
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${hostlink}/KDSDisplay/${usershopid}/${usershopid}/0/1`);
        const newData = response.data;

        // Compare the data for changes
        if (JSON.stringify(newData) !== JSON.stringify(items)) {
          setItems(newData);

          // Play notification tone for new items
          if (audioRef.current) {
            audioRef.current.play().catch((error) => console.error('Error playing tone:', error));
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Poll every 5 seconds
    const interval = setInterval(fetchData, 3000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [items, usershopid]);

  
  // Mark an item as Ready
  const markAsReady = async (shopvno, status) => {
    if (!messageShopVnoMessage.trim()) {
      toast.error("Type Message For Re-ring.");
      document.getElementById("messageShopVnoMessage").focus();
      return false;
    }
    
    setIsLoading(true);
    try {
      await axios.post(`${hostlink}/KDSDisplayUpdatePreparingNotification/${usershopid}/${shopvno}/${status}`, { status: 'Ready' });
      // await axios.post(`${hostlink}/KDSDisplayUpdate/${usershopid}/${shopvno}`, { status: 'Ready' });
      toast.success(`Order ${shopvno} Re-ring.`);

      // Fetch updated data
      const response = await axios.get(`${hostlink}/KDSDisplay/${usershopid}/${usershopid}/0/1`);
      setItems(response.data);
      setIsModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error marking item as Ready:', error);
      toast.error('Failed to update order status.');
      setIsLoading(false);
    }
  };


  return (
    <div style={{ padding: '20px', background: "linear-gradient(to bottom, #87CEEB, #ffffff)" }} >
      <h1 className='text-center'>Kitchen Display Status</h1>

      <div className="text-center rounded shadow">
        <table className="table rounded shadow" style={{ border: "1px solid white" }}>
          <thead className="text-center rounded shadow">
            <tr>
              <th className="text-start" scope="col">Order No</th>
              <th className="text-start" scope="col">Type</th>
              <th className="text-start" scope="col">Item</th>
              <th className="text-start" scope="col">Qty</th>
              <th className="text-start" scope="col">Action</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {items.map((res, x) => {
              // Compare with the previous row's shopvno
              const isNewGroup = x === 0 || res.kot.shopvno !== items[x - 1].kot.shopvno;

              return (
                <tr
                  key={x}
                  style={{
                    borderTop: isNewGroup ? '4px solid green' : 'none',  // Add a thick border for new groups
                  }}
                >
                  <td className="text-start">{isNewGroup ? res.kot.shopvno : ''}</td>
                  <td className="text-start">{isNewGroup && (<> {res.kottypeName} <br /> Table No: {res.kot.tablename} <br /> Waiter: {res.kot.wname} </>)} </td>
                  <td className="text-start" >{res.kot.itname} {res.kot.itcomment && (<p style={{ color: "red" }}> <b>{res.kot.itcomment}</b></p>)}  </td>
                  <td className="text-start">{res.kot.qty}</td>
                  <td className="text-start">
                    {isNewGroup && (
                      <button style={{ padding: '10px 15px', backgroundColor: res.kot.kdsstatus === 2 ? '#ffc107' : '#28a745', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', }} >
                        {res.kot.kdsstatus === 2 ? 'Processing' : 'Pending'}
                      </button>
                    )}
                    &nbsp;

                    {isNewGroup && res.kot.kdsstatus === 1 && ( <button onClick={(e) => { handleOpenModal(res.kot.shopvno) }} 
                        style={{ padding: '10px 15px', backgroundColor: res.kot.kdsstatus === 2 ? '#ffc107' : '#28a745',  color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', }} >
                        <i class="fa-regular fa-bell"></i>
                      </button>
                    )}

                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

      </div>
      {/* Notification sound */}
       <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                          <div><p>Type Message <br /> For Order No : {messageShopVno} ?</p>
                          <input type="text" onChange={(e) => { onChangevalue(e) }} name='messageShopVnoMessage' value={messageShopVnoMessage} class="form-control" id="messageShopVnoMessage" /> <br />
                          <div className="button-container"><button className="yes-button" onClick={(e) => { markAsReady(messageShopVno,messageShopVnoMessage) }}><i class="fa-regular fa-bell"></i> Re-ring</button> <button className="no-button" onClick={handleNoClick}>Cancel</button></div></div>
        </Modal>
      <audio ref={audioRef} src="/ready-tone.mp3" preload="auto"></audio>
      {isLoading && <Loading />}
      <ToastContainer autoClose={1000}></ToastContainer>
    </div>
  );
};




export default KitchenDisplayStatus