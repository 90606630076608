import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'

const RoomTypeMaster = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        channelchid: ""
    })
    const { deleteid, deletemsg, channelchid } = messagedata;
    const handleOpenModal = (e, flname,channelchidtable) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname, channelchid:channelchidtable })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [roomType, setRoomType] = useState({
        property_id: "",
        title: "Standard Room",
        count_of_rooms: 0,
        occ_adults: 0,
        default_occupancy: 0,
        occ_children: 0,
        occ_infants: 0,
    });


    const [refreshData, setRefreshData] = useState(false)
    const api = "/rtmas";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState()
    const Closeform = () => {
        window.location.assign("/RoomTypeMaster")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "RoomType",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}${api}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {
        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false };
        const inputValue = document.getElementById("inputname").value.trim();
        let responcechanel = "";
        if (inputValue === "") {
            toast.error("Please Enter Name")
            return false
        }
        document.getElementById("cmdsave").disabled = true
        
        
        
        try {
            if (document.getElementById("pagemode").innerHTML == ' Room Type [ NEW ]') {
                if (appState?.[0]?.chanelstatus === 1) {
                    const ld1 = {
                        room_type: { property_id: appState?.[0]?.chmid, title: rtname, count_of_rooms: parseInt(totalroom, 10), occ_adults: 3,  occ_children: 0, occ_infants: 0,  default_occupancy: parseInt(occupancy, 10), facilities: [],  room_kind: "room", capacity: null,  content: { description: roomtypedescription,  photos: [ { author: "Author Name",  description: "Room View",  kind: "photo", position: 0, url: "https://img.channex.io/af08bc1d-8074-476c-bdb7-cec931edaf6a/", }, ], }, }, };
                        await axios.post(`${appState?.[0]?.chmhostname}room_types`, ld1 ,{ headers: { [appState?.[0]?.chmapikeyname]: appState?.[0]?.chmuserapikey, }, }) .then((res)=>{ responcechanel = res.data?.data?.id; });
                }
                var ld = { ...savedata,chid: responcechanel, shopid: usershopid, narration: usershopid + document.getElementById("inputname").value.trim() }
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        var ld = { ...logdetail, newdesc: document.getElementById("inputname").value.trim(), operation: "New" }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/RoomTypeMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                if (appState?.[0]?.chanelstatus === 1) {
                    const ld1 = {
                        room_type: { property_id: appState?.[0]?.chmid,id: chid, title: rtname, count_of_rooms: parseInt(totalroom, 10), occ_adults: 3,  occ_children: 0, occ_infants: 0,  default_occupancy: parseInt(occupancy, 10), facilities: [],  room_kind: "room", capacity: null,  content: { description: roomtypedescription,  photos: [ { author: "Author Name",  description: "Room View",  kind: "photo", position: 0, url: "https://img.channex.io/af08bc1d-8074-476c-bdb7-cec931edaf6a/", }, ], }, }, };
                        console.log(ld1);
                        await axios.put(`${appState?.[0]?.chmhostname}room_types/${chid}`, ld1 ,{ headers: { [appState?.[0]?.chmapikeyname]: appState?.[0]?.chmuserapikey, }, }) .then((res)=>{ responcechanel = res.data?.data?.id; });
                }
                var ld = { ...savedata, narration: usershopid + document.getElementById("inputname").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = { ...logdetail, newdesc: "Name : " + document.getElementById("inputname").value.trim() }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/RoomTypeMaster")
                        }, 1000);
                    })
                    .catch(() => {
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        rtname: "",
        chid: "",
        shopid: usershopid,
        narration: "",
        totalroom: "",
        occupancy: "",
        roomtypedescription:""
    })
    const { rtname, chid, totalroom, occupancy,roomtypedescription } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const cmdEdit = (e) => {
        setPagemode(1);
        setIsLoading(true);
        axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setSavedata(Response.data);
                document.getElementById("pagemode").innerHTML = " Room Type [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                x.style.display = "block"
                document.getElementById("inputname").focus();
                document.getElementById("cmdnew").disabled = true
                setIsLoading(false);
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"
                var ld = { ...logdetail, olddesc: "Name : " + Response.data.rtname, operation: "Edit" }
                setlogdetail(ld);
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"
        document.getElementById("pagemode").innerHTML = " Room Type [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e, rtname) => {
        try {
            if (appState?.[0]?.chanelstatus === 1) {
                    await axios.delete(`${appState?.[0]?.chmhostname}room_types/${channelchid}`,{ headers: { [appState?.[0]?.chmapikeyname]: appState?.[0]?.chmuserapikey, }, }) .then((res)=>{  });
            }
            await axios.delete(`${hostlink}${api}/${e}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/RoomTypeMaster")
            }, 1000);
        } catch (error) { }
    }
    const columns = [
        { name: 'SNo', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "16%" },
        { name: 'Room Type', sortable: true, selector: row => row.rtname, },
        {
            name: 'Actions', "width": "30%", cell: (row) => (<div>
                {(userType === 0 || appState[1]?.[0]?.roomtype?.charAt(2) === '1') ? (<Link to={"/RoomTypeMaster"} onClick={(e) => { cmdEdit(`${row.id}`) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link>) : null}&nbsp;
                {(userType === 0 || appState[1]?.[0]?.roomtype?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(row.id, row.rtname,row.chid) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>) : null}&nbsp;
                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                    <div><p>Are you sure you want to delete this <br /> Room Type : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                </Modal>
            </div>), allowOverflow: true, button: true,
        },
    ];
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"

        document.getElementById("cmdsave").disabled = false
        setRefreshData(false)
        if(usershopid!=0){
        axios.get(`${hostlink}/${usershopid}${api}`)
            .then((Response) => {
                console.log("Data Fatched")
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        }
    }, [refreshData, usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-filter"></i> <span id='pagemode' > Room Type [ New ]</span></h5>
                {/* <form class="row g-3"> */}
                <div className="row">
                    <div className="col-md-6 col-6">
                        <label className='font-monospace' class="form-label"> Room Type</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='rtname' value={rtname} class="form-control" id="inputname" /> <br />
                    </div>
                    <div className="col-md-2 col-3">
                        <label className='font-monospace' class="form-label"> Total Room </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='totalroom' value={totalroom} class="form-control" id="totalroom" /> <br />
                    </div>
                    <div className="col-md-2 col-3">
                        <label className='font-monospace' class="form-label"> Occupancy </label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='occupancy' value={occupancy} class="form-control" id="occupancy" /> <br />
                    </div>
                    
                    <div className="col-md-6 col-6">
                        <label className='font-monospace' class="form-label"> Room Type Description</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='roomtypedescription' value={roomtypedescription} class="form-control" id="roomtypedescription" placeholder='Some Room Type Description Text' /> <br />
                    </div>

                    <div className="col-md-6 col-6">
                        <label className='font-monospace' class="form-label"> Channel ID</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='chid' value={chid} class="form-control" id="chid" disabled /> <br />
                    </div>
                </div>
                <div class="col-md-7 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-4 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-filter"></i> Room Type</h5>
                    </div>
                    <div className='col-md-4 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-4 text-end'>
                        {(userType === 0 || appState[1]?.[0]?.roomtype?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp; Create New </button>) : null}
                    </div>
                    <div style={{ height: "80vh" }} className='col-12 '>
                        <table class="table table-striped">
                            <tbody>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='530px'
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default RoomTypeMaster
