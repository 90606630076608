import React, { useState, useEffect } from "react";

const BluetoothTerminal = () => {
  const [port, setPort] = useState(null); // Store the serial port
  const [reader, setReader] = useState(null); // Store the reader for incoming data
  const [output, setOutput] = useState(""); // Store incoming data as a string
  const [isConnected, setIsConnected] = useState(false); // Connection status

  // Function to request and open a serial port
  

  const requestPort = async () => {
    try {
      const selectedPort = await navigator.serial.requestPort();
      await selectedPort.open({ baudRate: 9600 }); // Adjust baudRate as needed
      setPort(selectedPort);
      setIsConnected(true);
      startReading(selectedPort);
    } catch (error) {
      console.error("Failed to open serial port:", error);
      alert("Failed to open serial port. Please try again.");
    }
  };

  // Function to start reading data from the serial port
  const startReading = async (port) => {
    const decoder = new TextDecoder();
    const reader = port.readable.getReader();
    setReader(reader);

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          reader.releaseLock();
          break;
        }
        const data = decoder.decode(value, { stream: true });

        // Only update if the data has changed
        if (data !== output) {
          setOutput(data); // Update the output with the latest data
          
        }
      }
    } catch (error) {
      console.error("Error reading from serial port:", error);
    }
  };

  // Function to disconnect from the serial port
  const disconnect = async () => {
    try {
      if (reader) {
        reader.releaseLock(); // Release the lock on the reader
        setReader(null);
      }
      if (port) {
        if (port.readable) {
          await port.close(); // Only close the port if it's open
        }
        setPort(null);
        setIsConnected(false);
        setOutput(""); // Clear the output data
      }
    } catch (error) {
      console.error("Error disconnecting:", error);
    }
  };

  // Cleanup when the component unmounts
  useEffect(() => {
    return () => {
      if (reader) {
        reader.releaseLock();
      }
      if (port && port.readable) {
        port.close().catch((error) => {
          console.error("Error closing port during cleanup:", error);
        });
      }
    };
  }, [reader, port]);
  return (
    <div>
      <h1>Serial Terminal</h1>
      <div>
        {!isConnected ? (
          <button onClick={requestPort}>Connect to Serial Port</button>
        ) : (
          <button onClick={disconnect}>Disconnect</button>
        )}
      </div>

      {isConnected && (
        <div>
          <h3>Connected to Serial Port</h3>
          {/* Display data on a single line, continuously updating */}
          <p>{output}</p>
        </div>
      )}

      {!isConnected && <p>Please connect to a serial port to start reading data.</p>}
    </div>
  );
};

export default BluetoothTerminal;
