import React, { useState } from 'react';
import './Test.css';

const Test = () => {
  const [header, setHeader] = useState("Booking Confirmation");
  const [body, setBody] = useState("Hi John, your booking at GreenLeaf Resort is confirmed for 5th Jan 2025. Please contact us at +123456789 for more details.");
  const [footer, setFooter] = useState("Thank you for choosing GreenLeaf!");
  const [imageUrl, setImageUrl] = useState("");
  const [showReplyButton, setShowReplyButton] = useState(false);
  const [showCallNowButton, setShowCallNowButton] = useState(false);

  const handleAddMessage = () => {
    console.log("Header:", header);
    console.log("Body:", body);
    console.log("Footer:", footer);
    console.log("Image URL:", imageUrl);
    console.log("Reply Button:", showReplyButton);
    console.log("Call Now Button:", showCallNowButton);
    // Here you can add the connection logic with Java
  };

  return (
    <div className="mobile-view-container">
      <div className="mobile-device">
        <div className="whatsapp-view">
          <div className="sender-name">Sender: GreenLeaf Resort</div>
          <div className="message-bubble">
            {header && <div className="message-header">{header}</div>}
            {imageUrl && <img src={imageUrl} alt="Header" className="message-image" />}
            <div className="message-body">{body}</div>
            {footer && <div className="message-footer">{footer}</div>}
            {showReplyButton && (
              <button className="message-reply-button whatsapp-button">Reply</button>
            )}
            {showCallNowButton && (
              <button className="message-call-now-button whatsapp-button">Call Now</button>
            )}
          </div>
        </div>
      </div>

      <div className="message-inputs">
        <input
          type="text"
          placeholder="Header"
          value={header}
          onChange={(e) => setHeader(e.target.value)}
        />
        <input
          type="text"
          placeholder="Body"
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
        <input
          type="text"
          placeholder="Footer"
          value={footer}
          onChange={(e) => setFooter(e.target.value)}
        />
        <input
          type="text"
          placeholder="Image URL"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
        />
        <div className="button-toggles">
          <label>
            <input
              type="checkbox"
              checked={showReplyButton}
              onChange={(e) => setShowReplyButton(e.target.checked)}
            />
            Add Reply Button
          </label>
          <label>
            <input
              type="checkbox"
              checked={showCallNowButton}
              onChange={(e) => setShowCallNowButton(e.target.checked)}
            />
            Add Call Now Button
          </label>
        </div>
        <button onClick={handleAddMessage}>Add Message</button>
      </div>
    </div>
  );
};

export default Test;
