import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import './Reservation.css'
import numberToWords from 'number-to-words';
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import CheckOut from './CheckOut'
const Reservation = () => {
    const [selectedFilter, setSelectedFilter] = React.useState(0);

    const handleFilterChange = (filterType) => {
        setSelectedFilter((prev) => (prev === filterType ? "" : filterType)); // Toggle selection
    };

    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);
    const [roomtypeName, setroomtypeName] = useState("NA");
    const today = new Date();
    const [logroom, setLogroom] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const [isModalOpenCheckIn, setIsModalOpenCheckIn] = useState(false);
    
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;

    const handleOpenModalCancel = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpenCancel(true);
    };
    const handleOpenModalCheckIn = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpenCheckIn(true);
    };
    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const handleCloseModalCheckIn = () => { setIsModalOpenCheckIn(false); };
    const handleNoClickCheckIn = () => { console.log('User clicked No'); handleCloseModalCheckIn(); };
    
    const [refreshData, setRefreshData] = useState(false)
    const api = "/reservation";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [AccCode, setAccCode] = useState([])
    const [AccCodeBTC, setAccCodeBTC] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const Closeform = () => {
        window.location.assign("/Reservation")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "Reservation",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const getroombyid = (e) => {
        // console.log(`${hostlink}/rtmas/${e}`)
        axios.get(`${hostlink}/rtmas/${e}`)
            .then((resp) => {
                setroomtypeName(resp.data.rtname)
            })
            .catch(() => { setroomtypeName("NA") })

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)

    }
    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate: today.toISOString().split('T')[0]
    })
    const { blfromdate, bltodate } = viewbldate;
    const bldatechange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setBldate({ ...viewbldate, [name]: value })
        setRefreshData(true)
    }

    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}/reservationviewFilter/${blfromdate}/${bltodate}/${selectedFilter}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)

                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {
        setIsLoading(true);

        if (document.getElementById("1inputnamecheckin").value == "") {
            setIsLoading(false);
            toast.error("Please Select check in Date...")
            document.getElementById("1inputnamecheckin").focus();


            return false
        }



        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false };
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            setIsLoading(false);
            toast.error("Please Enter Mobile No")
            return false
        }

        try {
            if (document.getElementById("pagemode").innerHTML == ' Reservation [ NEW ]') {
                const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                var ld = { ...savedata, roomtypename: roomtypeName, restime: time, billamt: BillAmt, shopid: usershopid, narration: usershopid + document.getElementById("1inputname").value.trim() + document.getElementById("inputname").value.trim() + document.getElementById("1inputnamecheckin").value.trim() }
                await axios.post(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        var ld = {
                            ...logdetail,
                            newdesc: `Res No : ${Response.data}, Mob No : ${gmob}, Name : ${gname},  Advance : ${advance}`,
                            operation: "New"
                        }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/Reservation")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Reservation already exists for this Date.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                var ld = { ...savedata, roomtypename: roomtypeName, restime: time, billamt: BillAmt, narration: usershopid + document.getElementById("1inputname").value.trim() + document.getElementById("inputname").value.trim() }
                await axios.put(`${hostlink}${api}`, ld)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = {
                                ...logdetail,
                                newdesc: `Res No : ${id}, Mob No : ${gmob}, Name : ${gname} , Advance : ${advance}`,
                                operation: "Edit"
                            }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/Reservation")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        resdate: today.toISOString().split('T')[0],
        chdate: "",
        gmob: "",
        refrence: "",
        choutdate: "",
        gname: "",
        billtocompany: 0,
        billtocompanyname: "",
        add1: "",
        add2: "",
        email: "",
        rtype: "",
        advance: "",
        discount: "",
        remarks: "",
        cname: "",
        stday: 1,
        mg: "",
        rent: 0,
        billamt: 0,
        fg: "",
        cg: "",
        paidby: "",
        documentno: "",
        gstno: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomtypename: "",
        acccode: 0
    })

    const { id, documentno, acccode, shopresno, gstno, roomtypename, resdate, rent, chdate, billamt, gmob, gname, add1, add2, email, rtype, advance, discount, remarks, refrence, billtocompany, billtocompanyname, cname, stday, mg, fg, cg, paidby, choutdate } = savedata;
    // const onChangevalue = (e) => {
    //     const { name, value } = e.target;
    //     const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:<>?=]/g, '');
    //     setSavedata({ ...savedata, [name]: sanitizedValue });
    //     rentcalc()
    // }
    useEffect(() => {
        if (chdate && stday > 0) {
            const newChoutdate = new Date(chdate);
            newChoutdate.setDate(newChoutdate.getDate() + parseInt(stday, 10));
            setSavedata((prevData) => ({
                ...prevData,
                choutdate: newChoutdate.toISOString().split("T")[0],
            }));
            rentcalc();
        }

    }, [chdate, stday]);

    // Update stay days when either chdate or choutdate changes
    useEffect(() => {
        if (chdate && choutdate) {
            const startDate = new Date(chdate);
            const endDate = new Date(choutdate);
            const dayDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
            if (dayDifference >= 0) {
                setSavedata((prevData) => ({
                    ...prevData,
                    stday: dayDifference || 1, // Ensure at least 1 day
                }));
            }
            rentcalc();
        }
    }, [chdate, choutdate]);

    // Handle input changes dynamically
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        console.log(name)
        if (name === "billtocompany") {
            // Find the selected option based on the id
            const selectedOption = AccCodeBTC.find((x) => x.id === parseInt(value));
            const accname = selectedOption ? selectedOption.accname : ""; // Default to empty if not found

            setSavedata((prevData) => ({
                ...prevData,
                billtocompany: value, // Update ID
                billtocompanyname: accname, // Update accname
            }));
            return false;
        }
        // If changing check-out date, validate it
        if (name === "choutdate") {
            const startDate = new Date(chdate);
            const endDate = new Date(value);

            // If check-out date is earlier than check-in date, show an error message
            if (endDate < startDate) {
                alert("Check-out date cannot be earlier than check-in date.");
                return; // Stop further processing
            }
        }

        // Update state
        setSavedata((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        rentcalc();
    };

    const cmdEdit = async (e) => {
        setIsLoading(true);

        document.getElementById("inputname").focus();
        document.getElementById("cmdnew").disabled = true
        await axios.get(`${hostlink}/reservationsingle/${e}`)
            .then((Response) => {
                setPagemode(1);
                document.getElementById("pagemode").innerHTML = " Reservation [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                var roomname;
                x.style.display = "block"
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"

                setSavedata(Response.data);
                var ld = {
                    ...logdetail,
                    olddesc: `Res No : ${Response.data.id}, Mob No : ${Response.data.gmob}, Name : ${Response.data.gname}, Advance : ${Response.data.advance}`,
                    operation: "Delete"
                }
                setlogdetail(ld);
                setIsLoading(false);
                setDiscAmt(Number(Number(Response.data.rent * Number(Response.data.discount) / 100)))
                var billgst
                var stdays = Response.data.stday

                var currRent = (Number(Response.data.rent) - (Number(Number(Response.data.rent) * Number(Response.data.discount) / 100)))
                if (Number(GST_P_1) > Number(currRent)) {
                    billgst = "0"; setBillgstperc(0);
                } else if (Number(GST_P_2) > Number(currRent)) {
                    billgst = "5"; setBillgstperc(5);
                } else if (Number(GST_P_3) > Number(currRent)) {
                    billgst = "12"; setBillgstperc(12);
                } else if (Number(GST_P_4) > Number(currRent)) {
                    billgst = "18"; setBillgstperc(18);
                } else if (Number(GST_P_4) <= Number(currRent)) {
                    billgst = "28"; setBillgstperc(28);
                }
                setBillgstperc(billgst)
                setBillgstAmt((Number(currRent) * stdays) * billgst / 100)
                setBillAmt((Number(currRent) * stdays) + Number((Number(currRent) * stdays) * billgst / 100))
            })
            .catch((res) => { console.log("Data Not find") })
    }
    const cmdNew = () => {
        setPagemode(0);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"
        document.getElementById("pagemode").innerHTML = " Reservation [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("inputname").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
    }
    const deleteData = async (e, flname) => {

        try {

            await axios.get(`${hostlink}/reservationsingle/${e}`)
                .then((Responsenew) => {

                    var ld = {
                        ...logdetail,
                        newdesc: `Res No : ${Responsenew.data.id}, Mob No : ${Responsenew.data.gmob}, Name : ${Responsenew.data.gname}, Advance : ${Responsenew.data.advance}`,
                        olddesc: "",
                        operation: "Delete"
                    }
                    setlogdetail(ld);
                    saveLog(ld)
                    return false
                })
            console.log(`${hostlink}${api}/${e}`)
            await axios.delete(`${hostlink}${api}/${e}`)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/Reservation")
            }, 1000);
        } catch (error) { }
    }
    
    const trflinkCheckout = async (data1, data2) => {

        const queryParams = new URLSearchParams({
            data1, data2
        }).toString();
        window.location.assign(`/CheckIn?${queryParams}`);
    };
    const CancelReservation = async (e, flname) => {

        try {
            await axios.get(`${hostlink}/ReservationMascancel/${e}`)
            toast.success("Cancel Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/Reservation")
            }, 1000);
        } catch (error) { }
    }
    const [Billgstperc, setBillgstperc] = useState(0);
    const [BillgstAmt, setBillgstAmt] = useState(0);
    const [DiscAmt, setDiscAmt] = useState(0);
    const [BillAmt, setBillAmt] = useState(0);
    const rentcalc = () => {
        setDiscAmt(Number(Number(document.getElementById("22222inputname").value) * Number(document.getElementById("222inputname").value) / 100))
        var billgst
        var stdays = document.getElementById("22inputname").value

        var currRent = (Number(document.getElementById("22222inputname").value) - (Number(Number(document.getElementById("22222inputname").value) * Number(document.getElementById("222inputname").value) / 100)))
        // (((resp.exbed * resp.exbedch) + resp.rent) - (((resp.exbed * resp.exbedch) + resp.rent) * discountperc / 100))
        if (Number(GST_P_1) > Number(currRent)) {
            billgst = "0"; setBillgstperc(0);
        } else if (Number(GST_P_2) > Number(currRent)) {
            billgst = "5"; setBillgstperc(5);
        } else if (Number(GST_P_3) > Number(currRent)) {
            billgst = "12"; setBillgstperc(12);
        } else if (Number(GST_P_4) > Number(currRent)) {
            billgst = "18"; setBillgstperc(18);
        } else if (Number(GST_P_4) <= Number(currRent)) {
            billgst = "28"; setBillgstperc(28);
        }
        setBillgstperc(billgst)
        setBillgstAmt((Number(currRent) * stdays) * billgst / 100)
        setBillAmt((Number(currRent) * stdays) + Number((Number(currRent) * stdays) * billgst / 100))
    }

    useEffect(() => {
        if (usershopid != 0) {
            console.log(`${hostlink}/${usershopid}/reservationviewFilter/${blfromdate}/${bltodate}/${selectedFilter}`)
            axios.get(`${hostlink}/${usershopid}/reservationviewFilter/${blfromdate}/${bltodate}/${selectedFilter}`)
                .then((Response) => {
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        }
    }, [refreshData, usershopid, blfromdate, bltodate, selectedFilter])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const printdata = async (checkinid, shopvno) => {
        try {
            const response = await axios.get(`${hostlink}/reservationsingle/${checkinid}`);
            console.log(response.data)
            if (response && response.data) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/reservationsingle/${checkinid},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 210mm;
                            margin: 2mm; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 210mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };

    const generatePrintContent = (data) => {

        const companyGSTNO = "";
        const customergstno = "";

        // Ensure blgstamt is a valid number
        const blgstamt = 0;

        let print_cgst = 0;
        let print_sgst = 0;
        let print_igst = 0;

        // Compare the first two digits of both GST numbers
        if (customergstno.substring(0, 2) === "" || customergstno.substring(0, 2) === "NA" || customergstno.substring(0, 2) === "N/" || companyGSTNO.substring(0, 2) === customergstno.substring(0, 2)) {
            print_cgst = (blgstamt / 2).toFixed(2);
            print_sgst = (blgstamt / 2).toFixed(2);
            print_igst = 0;
        } else {
            print_igst = blgstamt;
        }

        // Convert to number format for further usage
        print_cgst = Number(print_cgst);
        print_sgst = Number(print_sgst);
        print_igst = Number(print_igst);

        let cgstRow = '';
        if (Number(print_cgst) > 0) {
            cgstRow = `
            <tr style="width: 100%; font-size: 15px;">
            <td style="width: 69%;">CGST Amount</td>
            <td style="width: 1%;">:</td>
            <td style="width: 30%; text-align: end;">${Number(print_cgst).toFixed(2)}</td>
            </tr>`;
        }

        let sgstRow = '';
        if (Number(print_sgst) > 0) {
            sgstRow = `
            <tr style="width: 100%; font-size: 15px;">
            <td style="width: 69%;">SGST Amount</td>
            <td style="width: 1%;">:</td>
            <td style="width: 30%; text-align: end;">${Number(print_sgst).toFixed(2)}</td>
            </tr>`;
        }

        let igstRow = '';
        if (Number(print_igst) > 0) {
            igstRow = `
            <tr style="width: 100%; font-size: 15px;">
            <td style="width: 69%;">IGST Amount</td>
            <td style="width: 1%;">:</td>
            <td style="width: 30%; text-align: end;">${Number(print_igst).toFixed(2)}</td>
            </tr>`;
        }

        let discountRow = '';


        let RoomServiceRow = '';

        let RoomTransferBillRow = '';

        let FoodChargeRow = '';
        let FlatDiscountRow = '';

        let AdditionalChargeRow = '';

        let RoundoffRow = '';
        let AdvanceRow = '';

        const billprintlineTC1 = appState?.[0]?.billprintlineTC1 || ""; const Html_billprintlineTC1 = billprintlineTC1 !== null ? `<b> <p style="text-align: left; font-size: 12px; width:210mm;">${billprintlineTC1}</p> </b>` : '';
        const billprintlineTC2 = appState?.[0]?.billprintlineTC2 || ""; const Html_billprintlineTC2 = billprintlineTC2 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC2}</p> ` : '';
        const billprintlineTC3 = appState?.[0]?.billprintlineTC3 || ""; const Html_billprintlineTC3 = billprintlineTC3 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC3}</p> ` : '';
        const billprintlineTC4 = appState?.[0]?.billprintlineTC4 || ""; const Html_billprintlineTC4 = billprintlineTC4 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC4}</p> ` : '';
        const billprintlineTC5 = appState?.[0]?.billprintlineTC5 || ""; const Html_billprintlineTC5 = billprintlineTC5 !== null ? `<p style="text-align: left; font-size: 10px; width:210mm;">${billprintlineTC5}</p> ` : '';


        const billprintline1 = appState?.[0]?.billprintline1 || ""; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:210mm;">${billprintline1}</p>` : '';
        const billprintline2 = appState?.[0]?.billprintline2 || ""; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:210mm;">${billprintline2}</p>` : '';
        const billprintline3 = appState?.[0]?.billprintline3 || ""; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:210mm;">${billprintline3}</p>` : '';
        const billprintline4 = appState?.[0]?.billprintline4 || ""; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:210mm;">${billprintline4}</p>` : '';
        const billprintline5 = appState?.[0]?.billprintline5 || ""; const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:210mm;">${billprintline5}</p>` : '';


        const custdlvdetailHtml = data.length > 0
            ? `${data[0][0].pay1amt !== null && data[0][0].pay1amt !== 0 ? `${data[0][3]}: ${data[0][0].pay1amt}` : ''
            }${data[0][0].pay2amt !== null && data[0][0].pay2amt !== 0
                ? `${data[0][0].pay1amt !== null && data[0][0].pay1amt !== 0 ? ', ' : ''}${data[0][4]}: ${data[0][0].pay2amt}`
                : ''
            }`
            : '';

        // Resulting HTML
        const resultHtml = custdlvdetailHtml !== '' ? `<span style="font-size: 14px;">${custdlvdetailHtml}</span>` : '';

        const Billamtinword = ``;

        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 190mm;}hr{width: 210mm;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 210mm;">
            <h1 style="text-align: center;">${appState?.[0]?.cname || '-'}</h1>
            ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5}
            <h4 style="text-align: center;">GSTIN : ${appState?.[0]?.gstno || '-'}</h4>
            <h1 style="text-align: center;">Reservation Receipt</h1>
    
            
            <table style="width: 205mm;">
               <tr style="border-bottom: 1px solid black; width: 205mm;">
                    <td style="width: 70%;border-bottom: 1px solid black;">
                                <table style="">
                                    <tr style="width: 100%;"><td style="width: 20%;">Reservation No</td><td style="width: 1%;">:</td><td style="width: 75%;"><b>${data.shopresno}</b></td></tr>    
                                    <tr style=" width: 100%;"><td style="width: 20%;">Reservation Date</td><td style="width: 1%;">:</td><td style="width: 75%;"><b>${new Date(data.resdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/')}</b></td></tr>    
                                    <tr style=" width: 100%;"><td style="width: 20%;">Reservation Time</td><td style="width: 1%;">:</td><td style="width: 75%;"><b>${data.restime}</b></td></tr>    
                              </table>
                    </td>
    
                        <td style="width: 30%;border-bottom: 1px solid black;">
                                
                                <table>
                                    <tr style="width: 100%;"><td style="width: 50%;">No Of Guest : </td><td style="width: 1%;">:</td><td style="width: 49%;"><b>${Number(data.mg) + Number(data.fg) + Number(data.cg)}</b></td></tr>    
                                    <tr style="width: 100%;"><td style="width: 50%;">Check IN Date </td><td style="width: 1%;">:</td><td style="width: 49%;"><b> ${new Date(data.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/')}</b></td></tr>    
                                    <tr style="width: 100%;"><td style="width: 50%;">Stay Days </td><td style="width: 1%;">:</td><td style="width: 49%;"><b> ${data.stday}</b></td></tr>    
                              </table>
                        </td>
             </tr">
              </table>
               <b>Guest Details</b>
                <table style="width: 205mm;border-Top: 1px solid black;">
                    <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                        <td style="width: 24%;border-bottom: 1px solid black;">Guest Name </td>
                        <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                        <td style="width: 35%;border-bottom: 1px solid black;">${data.gname}</td>
    
                        <td style="width: 15%;border-bottom: 1px solid black;">Mobile No </td>
                        <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                        <td style="width: 24%;border-bottom: 1px solid black;">${data.gmob}</td>
                    </tr">  
                    <tr style="border-bottom: 1px solid black; width: 205mm;"> 
                        <td style="width: 24%;border-bottom: 1px solid black;">Guest Email </td>
                        <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                        <td style="width: 35%;border-bottom: 1px solid black;">${data.email}</td>
    
                        <td style="width: 15%;border-bottom: 1px solid black;">GSTIN NO </td>
                        <td style="width: 1%;border-bottom: 1px solid black;">:</td> 
                        <td style="width: 24%;border-bottom: 1px solid black;">${data.gstno}</td>
                    </tr">          
                </table>
               <table style=" width: 205mm;">
                <tr style="border-bottom: 1px solid black; width: 205mm;"> <td style="width: 24%;border-bottom: 1px solid black;">Guest Address </td><td style="width: 1%;border-bottom: 1px solid black;">:</td> <td style="width: 75%;border-bottom: 1px solid black;">${data.add1}</td></tr">
                <tr style="border-bottom: 1px solid black; width: 205mm;"> <td style="width: 24%;border-bottom: 1px solid black;"> </td><td style="width: 1%;border-bottom: 1px solid black;">-</td> <td style="width: 75%;border-bottom: 1px solid black;">${data.add2}</td></tr">
               </table>
    
            </br>
               
            <table style="border-Top: 1px solid black; width: 205mm;">
            <b>Reservation Details</b>
            <tr style="border-bottom: 1px solid black;">
            <td style="width: 5%;border-bottom: 1px solid black;">SN</td>
            <td style="width: 22%;border-bottom: 1px solid black;">Check in Date</td>
            <td style="width: 38%;border-bottom: 1px solid black;">Room Type</td>
            <td style="width: 15%;border-bottom: 1px solid black;">Stay Days</td>
            <td style="width: 20%;border-bottom: 1px solid black;">Room Rent</td>
            </tr>
            
            <tr style="border-bottom: 1px solid black;">
            <td style="width: 5%;border-bottom: 1px solid black;">1</td>
            <td style="width: 22%;border-bottom: 1px solid black;">${new Date(data.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/')}</td>
            <td style="width: 38%;border-bottom: 1px solid black;">${data.roomtypename}</td>
            <td style="width: 15%;border-bottom: 1px solid black;">${data.stday}</td>
            <td style="width: 20%;border-bottom: 1px solid black;">${data.rent}</td>
            
            </tr>
                
    
              <table style="width: 205mm;">
               <tr style="border-bottom: 1px solid black; width: 205mm;">
               
                    <td style="width: 60%;border-bottom: 1px solid black;">
                    </br></br></br></br>
                                <b><u>Bank detail for Bill Payment through NEFT/RTGS:</u></b>
                                <table style="">
                                    <tr style="width: 100%;"><td style="width: 40%;">Name</td><td style="width: 1%;">:</td><td style="width: 55%;">${appState?.[0]?.accountname || '-'}</td></tr>    
                                    <tr style=" width: 100%;"><td style="width: 40%;">Bank A/c No</td><td style="width: 1%;">:</td><td style="width: 55%;">${appState?.[0]?.accountno || '-'}</td></tr>    
                                    <tr style=" width: 100%;"><td style="width: 40%;">IFSC Code</td><td style="width: 1%;">:</td><td style="width: 55%;">${appState?.[0]?.ifsc || '-'}</td></tr>    
                                    <tr style=" width: 100%;"><td style="width: 40%;">Bank Name</td><td style="width: 1%;">:</td><td style="width: 55%;">${appState?.[0]?.bankname || '-'}</td></tr>    
                                    <tr style="width: 100%;"><td style="width: 40%;">Branch</td><td style="width: 1%;">:</td><td style="width: 55%;">${appState?.[0]?.branch || '-'}</td></tr>    
                              </table>
                    </td>
    
                        <td style="width: 40%;border-bottom: 1px solid black;">
                                <table style="">
                                <tr style="width: 100%; font-size: 22px;"><td style="width: 69%;"><b>Advance</b></td><td style="width: 1%;">:</td><td style="width: 30%; text-align: end;"><b>${data.advance} </b></td></tr>    
                              </table>
                        </td>
             </tr">
              </table>
    
    
               
               ${Billamtinword}
               
    
                ${Html_billprintlineTC1}
                ${Html_billprintlineTC2}
                ${Html_billprintlineTC3}
                ${Html_billprintlineTC4}
                ${Html_billprintlineTC5}
                <table style="border-Top: 1px solid black; width: 205mm;"></table>
                
                    </br>
                    </br>
                    <table style="width: 205mm;border-bottom: 1px solid black;">
                    <tr style="border-bottom: 1px solid black; width: 205mm;">
                            <td style="width: 75%;">
                                <b>CASHIER SIGNATURE</b>
                            </td>
    
                            <td style="width: 25%;">
                                <b>GUEST SIGNATURE</b>
                            </td>
                    </tr">
    
                    
                    <tr style="border-bottom: 1px solid black; width: 205mm;">
                            <td style="width: 90%;">
                                <b></b>
                            </td>
                    </tr">
                    
                    
            </div>`;
    };

    
    useEffect(() => {
        if (usershopid != 0) {
            axios.get(`${hostlink}/shopmas/${usershopid}`)
                .then((res) => { console.log(res.data.gst5); setGST_P_1(res.data.gst5); setGST_P_2(res.data.gst12); setGST_P_3(res.data.gst18); setGST_P_4(res.data.gst28); })
                .catch(() => { })
            let x = document.getElementById("frm_controll_Add_Edit")
            x.style.display = "none"
            document.getElementById("cmdsave").disabled = false
            setRefreshData(false)
            axios.get(`${hostlink}/${usershopid}/accmas/2`)
                .then((ResponseAcc) => {
                    setAccCodeBTC(ResponseAcc.data)
                })
                .catch((ResponseAcc) => { "data Not Found" })
            axios.get(`${hostlink}/${usershopid}/rtmas`)
                .then((Response) => {
                    setRoomTYpe(Response.data)
                    const firstrtcode = Response.data[0];
                    axios.get(`${hostlink}/${usershopid}/accmascashbank`)
                        .then((ResponseAcc) => {
                            setAccCode(ResponseAcc.data)
                            const firstAccode = ResponseAcc.data[0];
                            setSavedata({
                                ...savedata,
                                rtype: firstrtcode ? firstrtcode.id : null,
                                acccode: firstAccode ? firstAccode.id : null
                            });
                        })
                        .catch((ResponseAcc) => { "data Not Found" })
                })
                .catch((Response) => { "data Not Found" })
            setSavedata({
                ...savedata,
                shopid: usershopid,
            });
        }
    }, [refreshData, usershopid])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-book"></i> <span id='pagemode' > Reservation [ New ]</span></h5>
                <div className='row'>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Reservation Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='resdate' value={resdate} id="1inputname" /> <br />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Mob No</label>
                        <input type="tel" onChange={(e) => { onChangevalue(e) }} pattern="[0-9]*" title="Please enter a 10-digit mobile number" required maxLength="10" onBlur={(e) => { const inputValue = e.target.value; if (inputValue.length < 10) { toast.error("Please enter valid mobile number"); e.target.focus(); } }} onKeyPress={(e) => { if (e.target.value.length >= 10 || isNaN(Number(e.key))) { e.preventDefault(); } }} name='gmob' value={gmob} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Guest Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gname' value={gname} class="form-control" id="inputname1" /> <br />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Add 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname11" /> <br />
                    </div>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Add 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Document No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='documentno' value={documentno} class="form-control" id="inputname1111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Select Document</label>
                        <input type="file" class="form-control" id="documentpath" />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Email</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname11111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname111111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="inputname1111111" /> <br />
                    </div>

                    <div class="col-md-4">
                        <label for="inputState" class="form-label">BTC</label>
                        <select id="billtocompany" onChange={(e) => { onChangevalue(e) }} name='billtocompany' value={billtocompany} class="form-select">
                            <option selected key={0} value={0}>NA</option>
                            {AccCodeBTC.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>
                    {/*- Stay Details -------------------------------------*/}
                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>

                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check In Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='chdate' value={chdate} id="1inputnamecheckin" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check Out Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='choutdate' value={choutdate} id="choutdate" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Stay Days</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='stday' value={stday} class="form-control" id="22inputname" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value); }} name='rtype' value={rtype} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>



                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Rent</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="22222inputname" />
                        <div style={{ fontSize: "12px", color: "blue" }}>
                            <table>
                                <tr ><td>Gst {Billgstperc} % </td><td> : {Number(BillgstAmt).toFixed(2)}</td></tr>
                                <tr ><td>Final Bill Amt &nbsp;</td><td> : {Number(BillAmt).toFixed(2)}</td></tr>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Dis %</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='discount' value={discount} class="form-control" id="222inputname" />
                        <div style={{ fontSize: "12px", color: "blue" }}>
                            <table>
                                <tr ><td>Disc Amt</td><td> : {Number(DiscAmt).toFixed(2)}</td></tr>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Male</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='mg' value={mg} class="form-control" id="11111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Female</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='fg' value={fg} class="form-control" id="111111inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Child</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cg' value={cg} class="form-control" id="2inputname" /> <br />
                    </div>



                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Advance</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='advance' value={advance} class="form-control" id="2222inputname" /> <br />
                    </div>
                    {/* <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Paid By</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='paidby' value={paidby} class="form-control" id="22222inputname" /> <br />
                    </div> */}
                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Paid By</label>
                        <select id="acccode" onChange={(e) => { onChangevalue(e) }} name='acccode' value={acccode} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {AccCode.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="col-md-5">
                        <label className='font-monospace' class="form-label">Remarks</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="12inputname" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Refrence</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='refrence' value={refrence} class="form-control" id="refrence" /> <br />
                    </div>

                </div>


                <div class="col-md-12 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1  align-self-center'>
                        <h5 className='font-monospace'><i class="fa-solid fa-door-open"></i> Reservation Details</h5>
                    </div>
                    <div className='col-md-3 rounded align-self-center' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-5 align-self-end ' >
                        <div className="row">
                            <div className="col-6 text-end">
                                <input
                                    id="reservationFilter"
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    checked={selectedFilter === 0} // Bind to state
                                    onChange={() => handleFilterChange(0)} // Handle change
                                />
                                Reservation
                            </div>
                            <div className="col-6 text-start">
                                <input
                                    id="checkinFilter"
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    checked={selectedFilter === 1} // Bind to state
                                    onChange={() => handleFilterChange(1)} // Handle change
                                />
                                Check-In
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <span className="f-size-on-mobile align-self-end">
                                    <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                                </span>
                                <span> <input type="date" id="blfromdate" name="blfromdate" onChange={(e) => { bldatechange(e); }} value={blfromdate} className="form-control" /></span>


                                <span className="f-size-on-mobile align-self-end">
                                    <label htmlFor="bltodate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                                </span>
                                <span>
                                    <input type="date" id="bltodate" name="bltodate" onChange={(e) => { bldatechange(e); }} value={bltodate} className="form-control" />
                                </span>
                            </div>
                        </div>


                    </div>
                    <div className='col-md-1 text-end  align-self-center'>
                        {(userType === 0 || appState[1]?.[0]?.reservation?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;New </button>) : null}
                    </div>
                    <div style={{ maxHeight: "100vh", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" className="f-size-on-mobile">R.No</th>
                                    <th scope="col" className="hide-on-mobile">Res Date</th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    <th scope="col" className="f-size-on-mobile">Checkin <span className="hide-on-mobile" >Date</span> <span className="detail-on-mobile"> Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Guest</th>
                                    <th scope="col" className="hide-on-mobile">Mobile</th>
                                    <th scope="col" className="hide-on-mobile">Address</th>
                                    <th scope="col" className="hide-on-mobile">Company</th>
                                    <th scope="col" className="hide-on-mobile">Remarks</th>
                                    <th scope="col" className="hide-on-mobile">Status</th>
                                    <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}  ><th scope="row" className="f-size-on-mobile text-center" style={{ verticalAlign: 'middle', height: '100%' }}>{res.shopresno}</th>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.resdate ? new Date(res.resdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.restime}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word", maxWidth: "20ch"  }}><span className="detail-on-mobile"><b> Check in Date : {res.chdate ? new Date(res.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>  <span className='hide-on-mobile'>{res.chdate ? new Date(res.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Guest : {res.gname} <br /> Mob : {res.gmob} <br /> Room Type : {res.roomtypename} <br /> Room Rent with GST : {res.billamt} <br /> Advance : {res.advance}, <span style={{ color: "blue" }}> <b> Checkout Date : {res.resdate ? new Date(new Date(res.resdate).getTime() + ((res.stday) * 24 * 60 * 60 * 1000)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</b> </span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ maxWidth: "12ch" }}>{res.gname}</td>
                                        <td className="hide-on-mobile" style={{ maxWidth: "12ch" }}>{res.gmob}</td>
                                        <td className="hide-on-mobile" style={{ maxWidth: "12ch", fontSize:"10px" }}>{res.add1}</td>
                                        <td className="hide-on-mobile" style={{ maxWidth: "12ch" }}>{res.cname}</td>
                                        <td className="hide-on-mobile" style={{ maxWidth: "12ch" }}>{res.remarks}</td>
                                        <td className="hide-on-mobile" style={{ maxWidth: "12ch" }}>{res.status === 1 ? 'Canceled' : ''}</td>
                                        <td  className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                            {(userType === 0 || appState[1]?.[0]?.reservation?.charAt(2) === '1') ? (<Link to={"/Reservation"} onClick={(e) => { cmdEdit(res.id) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" ></span></Link>) : null} &nbsp; 
                                            <Link onClick={(e) => printdata(res.id, res.id)} className='btn btn-sm btn-outline-primary'><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp;<span className="detail-on-mobile"><br /> <br /></span>
                                            {(userType === 0 || appState[1]?.[0]?.reservation?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(res.id, res.shopresno) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link>) : null} &nbsp;
                                            {(userType === 0 || appState[1]?.[0]?.reservation?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModalCancel(res.id, res.shopresno) }} className='btn btn-sm btn-dark'> <i class="fa-solid fa-ban"></i> <span className="hide-on-mobile"></span></Link>) : null} &nbsp; 
                                            <Link onClick={(e) => { handleOpenModalCheckIn(res.id, res.shopresno) }} className='btn btn-sm btn-outline-primary'> <i class="fa-solid fa-door-open"></i> <span className="hide-on-mobile"></span></Link>
                                            
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Reservation No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid, deletemsg) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCancel} onRequestClose={handleCloseModalCancel} contentLabel="Delete Confirmation" className="modal-content bg-outline-dark" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to Cancel this <br /> Reservation No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { CancelReservation(deleteid, deletemsg) }}>Yes</button> <button className="no-button" onClick={handleNoClickCancel}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCheckIn} onRequestClose={handleCloseModalCheckIn} contentLabel="Delete Confirmation" className="modal-content bg-outline-dark" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to Check-IN this <br /> Reservation No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { trflinkCheckout(deletemsg, "Reservation") }}>Yes</button> <button className="no-button" onClick={handleNoClickCheckIn}>No</button></div></div>
            </Modal>

            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default Reservation
