import React, { useEffect, useRef, useState, useContext } from 'react';
import './Test.css'; // Ensure correct path
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { Link } from 'react-router-dom';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import { ToastContainer, toast } from 'react-toastify';
const RoomList = ({ rooms }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);
    let usershopidref = '0';
    let usershopidCname = '-';
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    // const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';

    const trflink = async (roomcode, rent) => {
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select rtcode from roommas where shopid = ${usershopid} and id = ${roomcode} limit 1`)
            .then((resp) => {
                let roomtype = resp.data[0]
                const queryParams = new URLSearchParams({
                    roomcode, roomtype, rent
                }).toString();
                window.location.assign(`/CheckIn?${queryParams}`);
            })

    };

    const RoomClean = async (id) => {

        axios.post(`${hostlink}/room/statusfree/${id}`)
            .then((resp) => {
                toast.success("Something else...");
                window.location.assign(`/`);
            })
            .catch(() => {
                toast.error("Something else...")
            })

    };

    const trflinkCheckout = async (roomcode, rent) => {
        // console.log(`select * from checkinmas where shopid = ${usershopid} and status = 0 and `)
        // return false
        // axios.get(`${hostlink}/api/executeQuery?sqlQuery=select rtcode from roommas where shopid = ${usershopid} and id = ${roomcode} limit 1`)
        //     .then((resp) => {

        //         let roomtype = resp.data[0]
        //         const queryParams = new URLSearchParams({
        //             roomcode, roomtype, rent
        //         }).toString();
        //         window.location.assign(`/CheckIn?${queryParams}`);
        //     })

    };
    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, [usershopid]);


    return (
        <div className="list-container" >
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
            <h5 className="text-center">Rooms ({rooms.length})</h5>
            <div className="list-scroll" ref={listRef}>
                {rooms.map((room, index) => (

                    <div
                        className={`list-item ${room.stname === 'Booked' ? 'booked-bg' : 'available-bg'}`}
                        key={index}
                    >
                        <div className={`status-indicator ${room.stname === 'Booked' ? 'bg-danger' : 'bg-success'}`} ></div>
                        <span className="float-end" style={{ minWidth: '5px', display: 'inline-block', textAlign: 'right' }}>{room.name}</span>
                        <span className="float-end" style={{ minWidth: '75px', display: 'inline-block', textAlign: 'center' }}>{room.rtname}</span>
                        <span className="float-end" style={{ minWidth: '75px', display: 'inline-block', textAlign: 'right' }}>₹{room.rent}</span>
                        {/* <span className="float-end">
                            {room.stname === 'Booked' ? 'Booked' : 'Available'}
                        </span> */}
                        <span><button
                            onClick={() => {
                                if (room.status === 1) {
                                    trflinkCheckout(room.id, room.rent);
                                } else if (room.status === 2) {  // Fixed the syntax: else if instead of elseif
                                    RoomClean(room.id);
                                } else if (room.status === 3) {  // Fixed the syntax: else if instead of elseif
                                    RoomClean(room.id);
                                } else if (room.status === 4) {  // Fixed the syntax: else if instead of elseif
                                    window.location.assign("/Roommaster")
                                }
                                else {
                                    trflink(room.id, room.rent);
                                }
                            }}
                            className='btn btn-sm btn-dark'>
                            {/* {room.stname === 'Booked' ? 'Check-Out' : 'Check-In2'} */}
                            {room.status === 1
                                ? 'Check-Out'
                                : room.status === 2
                                    ? 'Clean'
                                    : room.status === 4
                                        ? 'Active'
                                        : room.status === 3
                                            ? 'Active'
                                            : 'Check-In'}
                        </button>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};
const CheckoutList = ({ checklistrooms }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;


    const trflink = async (roomcode, rent) => {
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select rtcode from roommas where shopid = ${usershopid} and id = ${roomcode} limit 1`)
            .then((resp) => {

                let roomtype = resp.data[0]
                const queryParams = new URLSearchParams({
                    roomcode, roomtype, rent
                }).toString();
                window.location.assign(`/CheckIn?${queryParams}`);
            })

    };

    const trflinkCheckout = async (data1, data2, data3, data4) => {

        const queryParams = new URLSearchParams({
            data1, data2, data3, data4
        }).toString();
        window.location.assign(`/CheckOut?${queryParams}`);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, [usershopid]);

    return (
        <div className="list-container" >
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
            {/* <h5 className="text-center">Rooms ({rooms.length})</h5> */}
            <div className="list-scroll" ref={listRef}>
                {checklistrooms.map((room, index) => (

                    <div
                        className={`list-item ${room.stname === 'Booked' ? 'booked-bg' : 'available-bg'}`}
                        key={index}
                    >
                        <div className={`status-indicator ${'bg-danger'}`} ></div>
                        <span className="float-end" style={{ minWidth: '5px', display: 'inline-block', textAlign: 'right' }}>{room[0].roomdescription}</span>
                        <span className="float-end" style={{ minWidth: '75px', display: 'inline-block', textAlign: 'center' }}>{room[0].gname}</span>
                        <span className="float-end" style={{ minWidth: '75px', display: 'inline-block', textAlign: 'center' }}>Mob No : {room[0].mobno}</span>
                        <span><button
                            onClick={(e) => trflinkCheckout(e, room[0].id, room[0].roomdescription, (room[0].indate ? new Date(new Date(room[0].indate).getTime() + Math.max(Number(room[0].stday), 1) * 24 * 60 * 60 * 1000) : null) ? new Date(new Date(room[0].indate).getTime() + Math.max(Number(room[0].stday), 1) * 24 * 60 * 60 * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-")}
                            className='btn btn-sm btn-dark'>Check-Out</button>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};
const TableListOrder = ({ orders }) => {

    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container">
            <h5 className="text-center">Running Orders</h5>
            <div className="list-scroll" ref={listRef}>
                <div className="row">
                    {orders
                        .map((room, index) => (
                            <div key={index} className={`col-3 mb-3`}>
                                <button
                                    className={`btn shadow rounded  ${room[2] === 2 ? 'btn-light' : 'btn-info'}`}
                                    style={{ height: '60px', width: "100%" }}
                                >
                                    {room[2] === 2 ? <i class="fa-solid fa-truck"></i> : <i class="fa-solid fa-gift"></i>} <br /> {room[0]}&nbsp;
                                </button>
                            </div>
                        ))}
                </div>
            </div>
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
        </div>
    );
};

const TableList = ({ tables }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container">
            <h5 className="text-center">Running Tables</h5>
            {/* <h5 className="text-center">Running Tables ({tables.filter(room => room.status === 0).length})</h5> */}
            <div
                className="list-scroll" ref={listRef}>
                <div className="row">
                    {tables
                        .filter(room => room.status === 1)
                        .map((room, index) => (
                            <div key={index} className="col-3 mb-3">
                                <Link
                                    to={"/FoodOrder"}
                                    className='btn shadow rounded btn-light '
                                    style={{ height: '50px', width: "100%", background: "#00b359", color: "white" }}
                                >
                                    {room.tname}
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
        </div>
    );
};
const TableListRunning = ({ tables }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container">
            <h5 className="text-center">Running Tables ({tables.filter(room => room.status == 1).length})</h5>
            <div className="list-scroll" ref={listRef}>
                {tables
                    .filter(room => room.status == 1) // Filter for only booked rooms
                    .map((room, index) => (
                        <div
                            className={`list-item ${room.status == '1' ? 'booked-bg' : 'available-bg'}`}
                            key={index}
                        >
                            <div className={`status-indicator ${room.status === 1 ? 'bg-danger' : 'bg-success'}`}></div>
                            <span>{room.tname}</span>
                            <span
                                className="float-end"
                                style={{ minWidth: '75px', display: 'inline-block', textAlign: 'right' }}
                            >
                                Available
                            </span>
                        </div>
                    ))}
            </div>
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
        </div>
    );
};

const Banner = ({ totalRooms, runningRooms, availableRooms, totalTables, runningTables, availableTables }) => {
    const today = new Date();
    const runningRoomPercentage = totalRooms > 0 ? (runningRooms / totalRooms) * 100 : 0;
    const runningTablePercentage = totalTables > 0 ? (runningTables / totalTables) * 100 : 0;
    const [RoomsDashboard, setRoomsDashboard] = useState([]);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    // const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';

    useEffect(() => {

        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select (select chdate from checkinroomdetails where shopid = ${usershopid} order by id desc limit 1) as lastcheckin,(select bldate from billmas where shopid = ${usershopid} order by id desc limit 1) as lastcheckOut,(select blamt from billmas where shopid = ${usershopid} order by id desc limit 1) as lastBillAmt,(select guestname from billmas where shopid = ${usershopid} order by id desc limit 1) as lastGuestName,(select shopvno from kotmas where shopid = ${usershopid} order by id desc limit 1) as lastKot,(select shopvno from salemas where shopid = ${usershopid} order by id desc limit 1) as lastBillrest,(select totblamt from salemas where shopid = ${usershopid} order by id desc limit 1) as lastBillamtrest,(select custname from salemas where shopid = ${usershopid} order by id desc limit 1) as dlvcust,(SELECT SUM(totblamt) FROM (SELECT DISTINCT shopvno, totblamt FROM salemas WHERE shopid = ${usershopid} AND bldate = '${today.toISOString().split('T')[0]}') as todaysale) as todaysale from shopmas where id =${usershopid}`)
            .then((response) => {
                setRoomsDashboard(response);
            })
            .catch((error) => {
                console.log("Data Not Found...", error);
            });
    }, [usershopid])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 mb-3 rounded shadow" style={{ background: "linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)) , url('/b3.jpeg')", backgroundSize: "100% 100%" }}>
                    <div  >
                        <div className="d-flex flex-column align-items-center">
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Total Rooms : </td><td> &nbsp;{totalRooms}</td></tr>
                                </tbody>
                            </table>
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Booked Rooms : </td><td> &nbsp;{runningRooms}</td><td>&nbsp;&nbsp;&nbsp;Available Rooms : </td><td> &nbsp;{availableRooms}</td></tr>
                                </tbody>
                            </table>

                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Last Check-in Date : </td><td> {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][0] ? new Date(RoomsDashboard.data[0][0]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : 'NO DATA'}</td></tr>
                                    <tr><td>Last Check-out Date : </td><td> {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][1] ? new Date(RoomsDashboard.data[0][1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : 'NO DATA'} </td></tr>
                                    <tr><td>Last Bill-amount : </td><td> &nbsp; {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][2] ? Number(RoomsDashboard.data[0][2]).toFixed(2) : 0}</td></tr>
                                    <tr><td>Guest Name : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][3] ? RoomsDashboard.data[0][3].toUpperCase() : 'NO DATA'}</td></tr>
                                </tbody>
                            </table>
                            <div className="progress w-75 mt-2">
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${runningRoomPercentage}%` }} aria-valuenow={runningRoomPercentage} aria-valuemin="0" aria-valuemax="100">
                                    {runningRoomPercentage.toFixed(0)}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3" style={{ fontSize: "14px" }}>
                    <div className="banner shadow" style={{ background: "linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)) , url('/b3.png')", backgroundSize: "100% 100%" }}>
                        <div className="d-flex flex-column align-items-center">
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Total Table : </td><td> &nbsp;{totalTables}</td></tr>
                                </tbody>
                            </table>
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Running Table : </td><td> &nbsp;{runningTables}</td><td>&nbsp;&nbsp;&nbsp; Available Table : </td><td> &nbsp;{availableTables}</td></tr>
                                </tbody>
                            </table>

                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>

                                    <tr><td>Last Order No : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][4] ? Number(RoomsDashboard.data[0][4]) : 0}</td></tr>
                                    <tr><td>Last Bill-No : </td><td> &nbsp; {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][5] ? Number(RoomsDashboard.data[0][5]) : 0}</td></tr>
                                    <tr><td>Last Bill-amount : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][6] ? Number(RoomsDashboard.data[0][6]) : 0}</td></tr>
                                    <tr><td>Customer Name : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][7] ? RoomsDashboard.data[0][7].toUpperCase() : 'NO DATA'}</td></tr>
                                    <tr><td>Today Sale : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][8] ? Number(RoomsDashboard.data[0][8]) : 0}</td></tr>
                                </tbody>
                            </table>
                            <div className="progress w-75 mt-2">
                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${runningTablePercentage}%` }} aria-valuenow={runningTablePercentage} aria-valuemin="0" aria-valuemax="100">
                                    {runningTablePercentage.toFixed(0)}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    );
};
const Home = () => {
    const today = new Date();
    const [selectedOption, setSelectedOption] = useState('option1');
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    let usershopidCname = '-';
    // const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [totalRooms, setTotalRooms] = useState(0);
    const [checkoutview, setcheckoutview] = useState(0);
    const [runningRooms, setRunningRooms] = useState(0);
    const [runningCleanRooms, setrunningCleanRooms] = useState(0);
    const [runningMainTain, setrunningMainTain] = useState(0);
    const [runningBlock, setrunningBlock] = useState(0);

    const [availableRooms, setAvailableRooms] = useState(0);
    const [totalTables, setTotalTables] = useState(0);
    const [runningTables, setRunningTables] = useState(0);
    const [availableTables, setAvailableTables] = useState(0);
    const [rooms, setRooms] = useState([]);
    const [checklistrooms, setChecklistrooms] = useState([]);
    const [tables, setTables] = useState([]);
    const [orders, setOrders] = useState([]);
    const [filterRoom, setfilterRoom] = useState('Available');

    const handleChangeRoomstatus = (e) => {
        if (e === 1) {
            setcheckoutview(1)
            axios.get(`${hostlink}/${usershopid}/rptcheckin/0`)
                .then((response) => {
                    setChecklistrooms(response.data);
                })
                .catch((error) => {
                    console.log("Room Data Not Found...", error);
                });
        } else {
            setcheckoutview(0)

        }

        axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/${e}`)
            .then((response) => {
                setRooms(response.data);
            })
            .catch((error) => {
                console.log("Room Data Not Found...", error);
            });
        // setfilterRoom(e)
    };


    const handleChangetatus = (e) => {

        axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/-1`)
            .then((response) => {
                const rooms = response.data; const total = rooms.length; const running = rooms.filter(room => room.stname === 'Booked').length;
                const available = total - running;
                setTotalRooms(total); setRunningRooms(running); setAvailableRooms(available); setRooms(rooms);
            })
            .catch((error) => {
                console.log("Room Data Not Found...", error);
            });

    };

    useEffect(() => {
        // Fetch room data
        if (usershopid != 0) {
            console.log(`${hostlink}/${usershopid}/roomstatus/0/0/-1`)
            axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/-1`)
                .then((response) => {
                    const rooms = response.data;
                    const total = rooms.length;
                    // const total = rooms.filter(room => room.status !=3 ).length;
                    const running = rooms.filter(room => room.status === 1).length;
                    const Clean = rooms.filter(room => room.status === 2).length;
                    const Maintain = rooms.filter(room => room.status === 3).length;
                    const block = rooms.filter(room => room.status === 4).length;
                    const available = total - running - Clean - Maintain - block;

                    setTotalRooms(total);
                    setRunningRooms(running);
                    setAvailableRooms(available);
                    setrunningCleanRooms(Clean);
                    setrunningMainTain(Maintain);
                    setrunningBlock(block)
                    handleChangeRoomstatus(0)

                })
                .catch((error) => {
                    console.log("Room Data Not Found...", error);
                });

            // Orders

            axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,blno,kottype from kotmas where shopid = ${usershopid} and kottype in (2,3) and kotdate = '${today.toISOString().split('T')[0]}' group by shopvno,blno,kottype`)
                .then((response) => {
                    setOrders(response.data);

                })
                .catch((error) => {
                    console.log("Table Data Not Found...", error);
                });
            // Fetch table data
            axios.get(`${hostlink}/${usershopid}/table`)
                .then((response) => {
                    const tables = response.data;
                    const total = tables.length;
                    const running = tables.filter(table => table.status === 1).length;
                    const available = total - running;

                    setTotalTables(total);
                    setRunningTables(running);
                    setAvailableTables(available);
                    setTables(tables);
                })
                .catch((error) => {
                    console.log("Table Data Not Found...", error);
                });
        }
    }, [usershopid]);

    return (
        <div className=""
            style={{
                height: "92vh", color: "#fff", margin: "0", padding: "0", overflowX: "hidden", background: "linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)) , url('/b1.jpeg')", height: "92vh", backgroundRepeat: "no-repeat", width: "100%", backgroundAttachment: "fixed", backgroundSize: "100% 100%"
            }}

        >
            <div className='container-fluid'>
                <div className='row'>
                    {/* Rooms */}


                    {(userType === 0 || appState[1]?.[0]?.checkin?.charAt(0) === '1') ? (<div className='col-md-6 font-Bahnschrift Condensed'>
                        <h5>Rooms</h5>
                        <div className="col-12 rounded bg-black">
                            <div className="row rounded bg-black" style={{ display: 'flex', border: "1px solid white" }}>
                                <div className="col-md-3" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(0) }} class="btn btn-primary w-100"> <i class="fa-solid fa-door-open"></i> Available : {availableRooms}</button></div>
                                <div className="col-md-3" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(1) }} class="btn btn-danger w-100"><i class="fa-solid fa-people-roof"></i> Booked : {runningRooms}</button></div>
                                <div className="col-md-2" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(2) }} class="btn btn-warning w-100"> <i class="fa-solid fa-person-digging"></i> ({runningCleanRooms})</button></div>
                                <div className="col-md-2" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(3) }} class="btn btn-secondary w-100"><i class="fa-solid fa-screwdriver-wrench"></i> ({runningMainTain})</button></div>
                                <div className="col-md-2" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(4) }} class="btn btn-info w-100"><i class="fa-solid fa-ban"></i> ({runningBlock})</button></div>
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12">
                            {checkoutview === 1 ? (
                                <CheckoutList checklistrooms={checklistrooms} />
                            ) : (
                                <RoomList rooms={rooms} />
                            )}
                        </div>
                        <Banner totalRooms={totalRooms} runningRooms={runningRooms} availableRooms={availableRooms} totalTables={totalTables} runningTables={runningTables} availableTables={availableTables} />
                    </div>) : null}
                    {/* Running Order  */}
                    <div className='col-md-6  font-Bahnschrift Condensed'>
                        <h5><b><u></u></b> <br />
                            {/* <label> <input type="radio" value="option1" checked={selectedOption === 'option1'} onChange={handleChange} />  All </label> &nbsp;
                            <label> <input type="radio" value="option2" checked={selectedOption === 'option2'} onChange={handleChange} />   <i class="fa-solid fa-table"></i> Dine-in </label>&nbsp;
                            <label> <input type="radio" value="option3" checked={selectedOption === 'option3'} onChange={handleChange} />   <i class="fa-solid fa-truck"></i> Delivery </label>&nbsp;
                            <label> <input type="radio" value="option4" checked={selectedOption === 'option4'} onChange={handleChange} />   <i class="fa-solid fa-gift"></i> Takeaway </label>&nbsp; */}
                        </h5>
                        <div className="col-md-12">
                            <TableList tables={tables} />
                        </div>
                        <div className="col-md-12">
                            {/* <TableListOrder orders={orders} /> */}
                        </div>
                    </div>

                </div>

            </div>

            {/* <div className="row">
                <div className="col-md-3">
                    <RoomList rooms={rooms} />
                </div>
                <div className="col-md-3">
                    <RoomListBooked rooms={rooms} />
                </div>
                <div className="col-md-3">
                    <TableList tables={tables} />
                </div>
                <div className="col-md-3">
                    <TableListRunning tables={tables} />
                </div>
            </div> */}
            <br /><br />

            <footer className="fixed-bottom"><marquee className='bg-dark text-light p-1' behavior="alternate" direction="left">.......... BillHost-MYPCKART offering customized software solutions for your business according to your requirement. We have multiple software products with us with all required modules. (For more details, contact us at: +91-9910496797)..........</marquee> </footer>


        </div>
    );
};

export default Home;
